.a-loader {
  display: flex;
}

.a-loader__dot-1,
.a-loader__dot-2,
.a-loader__dot-3 {
  width: 0.65rem;
  height: 0.6rem;
  border-radius: 50%;
  margin: 0.5rem;
}

.a-loader__dot-1 {
  animation: scale 1.6s -0.32s linear infinite;
  background: map-get($primary-colors, 'yellow-noon');
}
.a-loader__dot-2 {
  animation: scale 1.6s -0.16s linear infinite;
  background: map-get($primary-colors, 'yellow-noon');
}
.a-loader__dot-3 {
  animation: scale 1.6s linear infinite;
  background: map-get($primary-colors, 'yellow-noon');
}

@keyframes scale {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(2);
    transform: scale(2);
  }
}
