.m-return-isbn {
  display: flex;
  flex-direction: row;

  margin-top: 2rem;
  margin-bottom: 2rem;
}

.m-return-isbn__left-right {
  flex: 1;
}
