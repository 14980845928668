.a-text-label__small {
  font-size: 0.75rem;
}

.a-text-label__medium {
  font-size: 1rem;
  line-height: 1.7rem;
}

.a-text-label--bold {
  font-weight: bold;
}

@media (min-width: map-get($breakpoints, 'tablet-large')) {
  .a-text-label__small {
    font-size: 1rem;
  }

  .a-text-label__medium {
    font-size: 1.2rem;
    line-height: 1.7rem;
  }
}
