@import '../../_settings/typography.scss';
@import '../../_settings/variables.scss';

.a-subtitle {
  font-family: $font-IBM;
  display: flex;
  justify-content: space-between;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;

  align-items: center;

  color: map-get($primary-colors, 'gray-day');

  background-color: map-get($primary-colors, 'yellow-noon');
}

.a-subtitle__text {
  font-size: 1.25rem;
  font-family: $font-IBM;
  line-height: 2rem;
  letter-spacing: 1px;
  font-weight: bold;
}

.a-subtitle__button {
  outline: none;
  border: none;
  width: 100%;
  cursor: pointer;
}

.a-subtitle__icon {
  height: 1.5rem;
  width: 1.5rem;
  transform: rotate(180deg);
  transform-origin: center center;
}

@media (min-width: map-get($breakpoints, 'tablet-large')) {
  .a-subtitle {
    display: flex;
    justify-content: space-between;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 2rem;
    padding-right: 2rem;

    font-family: $font-IBM;
    font-size: 1.5rem;
    line-height: 2rem;
    letter-spacing: 1px;
    font-weight: bold;
  }

  .a-subtitle__text {
    font-size: 1.5rem;
  }
}
