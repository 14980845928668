.o-subtitle-book-overview {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-auto-rows: 1fr;
  gap: 2rem;
  row-gap: 2rem;
  column-gap: 5rem;

  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

.o-subtible-book__not-found {
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
  grid-column: 1/-1;
}

@media (min-width: map-get($breakpoints, 'tablet')) {
  .o-subtitle-book-overview {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: map-get($breakpoints, 'tablet-large')) {
  .o-subtitle-book-overview {
    grid-template-columns: repeat(3, 1fr);
  }
  .o-subtible-book__not-found {
    font-size: 1.2rem;
    font-weight: bold;
    line-height: 1.7rem;
    text-align: center;
    grid-column: 1/-1;
  }
}

@media (min-width: map-get($breakpoints, 'desktop')) {
  .o-subtitle-book-overview {
    grid-template-columns: repeat(4, 1fr);
  }
}
