.a-return-button {
  border: none;
  outline: none;

  background-color: transparent;

  cursor: pointer;
}

.a-return-button__svg {
  height: 2rem;
  width: 2rem;
}
