.t-admin-add-book__isbn-rfid-container {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
}

.t-admin-add-book__submit {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
  display: flex;
  justify-content: center;
  gap: 2rem;
}
