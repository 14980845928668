.a-button {
  background-color: map-get($primary-colors, 'yellow-noon');

  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 2rem;
  padding-right: 2rem;

  min-width: 10rem;

  cursor: pointer;

  outline: none;
  border-radius: $border-rounded;

  font-size: 1rem;
}

.a-button__primary {
  background-color: map-get($primary-colors, 'yellow-noon');
  border: 1px solid map-get($primary-colors, 'yellow-noon');
}

.a-button__primary:hover {
  background-color: map-get($primary-colors, 'yellow-morning');
}

.a-button__primary-monochrome {
  background-color: map-get($primary-colors, 'gray-day');
  border: 1px solid map-get($primary-colors, 'gray-night');
}

.a-button__primary-monochrome:hover {
  color: map-get($primary-colors, 'yellow-afternoon');
  border: 1px solid map-get($primary-colors, 'yellow-afternoon');
  font-weight: bold;
}

.a-button__secondary {
  background-color: map-get($primary-colors, 'gray-day');
  border: 1px solid map-get($primary-colors, 'gray-day');
}

.a-button__secondary:hover {
  color: map-get($primary-colors, 'yellow-afternoon');
  font-weight: bold;
}
