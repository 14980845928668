.o-form {
  display: grid;
  grid-template-columns: 1.5fr 1.5fr 1fr;
  // grid-template-rows: repeat(7, 1fr);
  grid-auto-rows: minmax(1fr, auto);
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;
}

.o-form-item {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.o-form-item__2-cols {
  grid-column: 1 / span 2;
}

.o-form-item__3-cols {
  grid-column: 1 / span 3;
}

.o-form__summary {
  grid-row: 4 / 7;
}

.o-form__cover {
  grid-column: 3;
  grid-row: 2/ 7;
  margin: auto;
  object-fit: cover;
  width: 100%;
  border-radius: $border-radius;
}

.o-form__cover-placeholder {
  grid-column: 3;
  grid-row: 2/ 7;
  padding: 2rem;
  object-fit: cover;
  width: 80%;
  border-radius: $border-radius;
}

.o-form__textarea {
  width: 100%;
  height: 100%;
  resize: none; /* Otherwise grid would have problems */
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
}

.o-form__radio-uitgeleend {
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  padding-left: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-left: 1.5rem;
  border-left: 1px solid;
}

.o-form__select .o-form__select__control--is-focused.o-form__select__control--menu-is-open {
  box-shadow: 0 !important;
  outline-color: map-get($primary-colors, 'yellow-noon') !important;
  outline-style: auto !important;
  outline-width: 1px !important;
  border-radius: 0.5rem;
}

.o-form__select__control {
  border-radius: 0.5rem !important;
}
.o-form__select__menu-list {
  border-radius: 0.5rem !important;
}
