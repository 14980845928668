.a-delete-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;

  // position in grid
  justify-self: center;
  align-self: center;

  cursor: pointer;

  padding-top: 0.35rem;
  padding-bottom: 0.35rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;

  outline: none;
  border: 1px solid map-get($primary-colors, 'error-noon');
  border-radius: 50px;

  background-color: transparent;
  color: map-get($primary-colors, 'error-noon');
  font-size: 1rem;
}

.a-delete-button__svg {
  fill: map-get($primary-colors, 'error-afternoon');
  height: 1.25rem;
  width: 1.25rem;
}

.a-delete-button:hover,
.a-delete-button:focus {
  background-color: map-get($primary-colors, 'error-noon');
  color: map-get($primary-colors, 'gray-day');
}

.a-delete-button:hover svg,
.a-delete-button:focus svg {
  fill: map-get($primary-colors, 'gray-day');
}

@media (min-width: map-get($breakpoints, 'tablet-large')) {
  .a-delete-button__svg {
    height: 1.5rem;
    width: 1.5rem;
  }
}
