$font-Barlow: 'Barlow', Arial, Helvetica, sans-serif;
$font-IBM: 'IBM_Plex_Serif', Arial, Helvetica, sans-serif;

$base-font-size: 16px;
$base-line-height: 1.6;

@font-face {
  font-family: 'Barlow';
  src: url('../../resources/fonts/Barlow/Barlow-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'IBM_Plex_Serif';
  src: url('../../resources/fonts/IBM_Plex_Serif/IBMPlexSerif-Regular.ttf') format('truetype');
}

h1 {
  font-family: $font-IBM;
  font-size: 2rem;
  line-height: 2.5rem;
}

button {
  font-family: $font-Barlow;
}
