.m-book-overview-item {
  display: grid;

  text-align: justify;
  text-justify: inter-word;

  height: 95%;

  margin-top: 1rem;
  margin-bottom: 1rem;
  margin-left: 2rem;
  margin-right: 2rem;
}

.m-book-overview-item__container {
  display: flex;
  justify-content: center;
}

.m-book-overview-item__image {
  // grid-column: 1 / 3;
  grid-row: span 3 / span 3;
  justify-self: center;
  align-self: center;
  display: inline-block;
  position: relative;
}

.m-book-overview-button {
  align-items: flex-start;
  gap: 1rem;

  height: 95%;

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(5, 1fr);

  text-align: justify;
  text-justify: inter-word;

  cursor: pointer;

  outline: none;
  border: none;
  background-color: transparent;
  transition: transform 0.2s;
}

.m-book-overview-button:hover .m-book-overview-item__image {
  transform: scale(1.15);
}

@keyframes scaleUp {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  80%,
  100% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
}
