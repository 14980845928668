@import 'typography.scss';
@import 'variables.scss';

html {
  background-color: map-get($primary-colors, 'gray-day');
}

body {
  font-family: $font-Barlow;
  color: map-get($primary-colors, 'gray-noon');
}

main {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 90vw;
  margin-left: 5vw;
  margin-right: 5vw;
}

@media (min-width: map-get($breakpoints, 'tablet')) {
  main {
    width: 80vw;
    margin-left: 10vw;
    margin-right: 10vw;
  }
}

img {
  border-radius: $border-radius;
}

header {
  position: sticky;
  align-self: flex-start;
  top: 0;
  overflow-y: auto;
  z-index: 100;
}

input,
textarea {
  font-size: 1rem;
}
