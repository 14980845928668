.a-alert {
  display: flex;
  align-items: center;
  gap: 0.5rem;

  position: fixed;

  left: 50%;
  transform: translateX(-50%);

  top: 1rem;

  border-radius: 0.25rem;

  min-width: 20rem;

  overflow: hidden;
  z-index: 1000;
}

.a-alert__success {
  background-color: map-get($primary-colors, 'success-morning');
  color: map-get($primary-colors, 'success-afternoon');
}

.a-alert__warning {
  background-color: map-get($primary-colors, 'warning-morning');
  color: map-get($primary-colors, 'warning-afternoon');
}

.a-alert__border-left {
  position: relative;
  align-self: stretch;
  border-radius: 0.25rem;
  width: 0.5rem;
}

.a-alert__border-left__success {
  background-color: map-get($primary-colors, 'success-noon');
}

.a-alert__border-left__warning {
  background-color: map-get($primary-colors, 'warning-noon');
}

.a-alert__icon {
  height: 1.5rem;
  width: 1.5rem;
}

.a-alert__label {
  padding-top: 16px;
  padding-bottom: 1rem;
}

.a-alert__button {
  position: relative;

  align-self: stretch;
  margin-left: auto;

  display: flex;
  align-items: center;

  outline: none;
  border: 0px;
  border-radius: 0.25rem;

  cursor: pointer;
}

.a-alert__button__success {
  background-color: map-get($primary-colors, 'success-noon');
}

.a-alert__button__warning {
  background-color: map-get($primary-colors, 'warning-noon');
}

.a-alert__button-icon {
  padding-left: 0.5rem;
  padding-right: 0.5rem;

  height: 1rem;
  width: 1rem;
}

.a-alert__button-icon__success {
  fill: map-get($primary-colors, 'success-afternoon');
}

.a-alert__button-icon__warning {
  fill: map-get($primary-colors, 'warning-afternoon');
}
