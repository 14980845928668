.o-pop-up-modal {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparentize(map-get($primary-colors, 'gray-day'), 0.5);
  z-index: 10;
}

.o-pop-up-modal__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;

  padding-top: 4rem;
  padding-bottom: 4rem;

  width: 50%;

  text-align: center;

  background-color: map-get($primary-colors, 'gray-day');

  border: 0.5rem solid map-get($primary-colors, 'yellow-noon');
  box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
}

.o-pop-up-modal__buttons {
  display: flex;
  flex-direction: row;
  gap: 2rem;
}

.o-pop-up-modal__text-container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  width: 80%;

  font-size: 1.25rem;
}

.o-pop-up-modal__text-author {
  font-size: 1rem;
  font-weight: bold;
}
