.a-input-field__container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  height: 100%;
}

.a-input-field {
  font-family: $font-Barlow;
  border: 1px solid map-get($primary-colors, 'gray-morning-8am');
  outline: none;
  border-radius: $border-radius;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  cursor: text;
  background-color: none;
}

.a-input-field__filled {
  background-color: map-get($primary-colors, 'information-sunrise');
}

.a-input-field__radio {
  margin-right: 0.5rem;
}

.a-input-field__textarea {
  width: 100%;
  height: 100%;
  resize: none; /* Otherwise grid would have problems */
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
}

.a-input-field:active {
  border: 1px solid map-get($primary-colors, 'yellow-noon');
}

.a-input-field:focus {
  border: 1px solid map-get($primary-colors, 'yellow-noon');
}
