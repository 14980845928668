.a-reader-banner {
  font-family: $font-IBM;
  font-size: 1rem;
  font-weight: bold;
  color: map-get($primary-colors, 'gray-night');

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.a-reader-banner__container {
  position: absolute;
  display: flex;
  z-index: 5;
  bottom: 2rem;

  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;

  width: 130%;
  transform: translate(-15%, 30%);
  transition: width 0.5s ease-in-out;
  background-color: map-get($primary-colors, 'yellow-morning-6am');
  box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
}

.a-reader-banner__hover {
  position: absolute;
  display: flex;
  visibility: hidden;
  z-index: 5;
  bottom: -2rem;

  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;

  width: 130%;
  transform: translate(-15%, 30%);

  background-color: map-get($primary-colors, 'yellow-morning-6am');
  box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
}

.a-reader-banner:hover + .a-reader-banner__hover {
  visibility: visible;
}
.a-reader-banner__container:hover {
  transition: width 0.5s ease-in-out;
  width: 200%;
  // transform: translate(-25%, 30%);
}
