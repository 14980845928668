.o-pop-up-modal {
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparentize(map-get($primary-colors, 'gray-day'), 0.5);
  z-index: 2000;
}

.o-pop-up-modal__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;

  padding-top: 4rem;
  padding-bottom: 4rem;

  width: 50%;

  text-align: center;

  background-color: map-get($primary-colors, 'gray-day');

  border: 0.5rem solid map-get($primary-colors, 'yellow-noon');
  box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
}

.o-pop-up-modal__buttons {
  display: flex;
  flex-direction: row;

  gap: 2rem;
}

.o-pop-up-form__rfid {
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 70%;

}

.o-pop-up-form__container {
  display: flex;
  gap: 2rem;
  width: 65%;
}

.o-pop-up-modal__img {
  max-height: 20rem;
}

.o-pop-up-form__span-svg {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.o-pop-up-form__svg {
  height: 2.5rem;
  width: 2.5rem;
}
