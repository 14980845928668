// Colors
$primary-colors: (
  'error-noon': #cc2200,
  'error-afternoon': #b31700,
  'gray-morning-6am': #f5f5f5,
  'gray-morning-7am': #d6d6d6,
  'gray-morning-8am': #bababa,
  'gray-day': #ffffff,
  'gray-noon': #343434,
  'gray-night': #050505,
  'information-sunrise': #ebf1ff,
  'success-morning': #54e0ba,
  'success-noon': #00d198,
  'success-afternoon': #00a779,
  'warning-morning': #ffaf55,
  'warning-noon': #ff8802,
  'warning-afternoon': #e27101,
  'yellow-morning-6am': #fdefb0,
  'yellow-morning': #fce064,
  'yellow-afternoon': #ecb817,
  'yellow-noon': #fad018,
);

// Responsive breakpoints
$breakpoints: (
  phone-small: 321px,
  phone: 480px,
  phone-large: 680px,
  tablet: 960px,
  tablet-large: 1140px,
  desktop: 1440px,
  desktop-large: 1640px,
) !default;

$border-radius: 0.5rem;
$border-rounded: 50px;
