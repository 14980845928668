.m-header {
  background-color: map-get($primary-colors, 'gray-day');
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;

  padding-top: 2rem;
  padding-bottom: 2rem;
}

.m-header__icon-title-button {
  display: flex;
  align-items: center;
  gap: 2rem;

  cursor: pointer;
  border: none;
  outline: none;
  background-color: transparent;
}

.m-header__title {
  font-weight: bold;
  font-size: 1.5rem;
}

.m-header__button-search {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

@media (min-width: map-get($breakpoints, 'tablet-large')) {
  .m-header {
    flex-direction: row;
    gap: 0;
  }
  .m-header__button-search {
    display: flex;
    flex-direction: row;
    gap: 1rem;
  }
  .m-header__title {
    font-weight: bold;
    font-size: 2rem;
  }
}

@media (min-width: map-get($breakpoints, 'phone-large')) {
  .m-header__button-search {
    flex-direction: row;
  }
}
