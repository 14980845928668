.a-search-bar {
  position: relative;
  cursor: pointer;
}

.a-search-bar input {
  font-family: $font-Barlow;

  border: 1px solid map-get($primary-colors, 'gray-morning-8am');
  outline: none;
  border-radius: $border-radius;

  width: 16rem;
  padding-left: 2rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  cursor: text;
}

.a-search-bar input:active {
  border: 1px solid map-get($primary-colors, 'yellow-noon');
}

.a-search-bar input:focus {
  border: 1px solid map-get($primary-colors, 'yellow-noon');
}

.a-search-bar svg {
  position: absolute;
  left: 0;
  padding-top: 0.5rem;
  padding-left: 0.5rem;
  transition: 0.3s;
}

@media (min-width: map-get($breakpoints, 'phone')) {
  .a-search-bar input {
    width: 18rem;
  }
}
